.menu {
  box-shadow: 0 10px 10px 0 var(--color-black);
  background-color: var(--color-black) !important;
  padding: 0 var(--spacing-xl);

  .full-logo {
    border-radius: 0;
    height: 32px;
    width: auto;
  }

  .top-bar {
    padding: 0;
  }

  .active {
    background-color: var(--color-grey-10);
    border-radius: var(--border-radius-sm);
    a {
      color: var(--color-green-6);
    }
  }

  .profile-button {
    padding-right: 0;
  }

  .nav-hr-separator {
    color: rgba(255, 255, 255, 0.2);
    background-color: rgba(255, 255, 255, 0.2);
    border: none;
    height: 0.125px;
    margin: 4px 0;
    width: 100%;
  }

  .symbol-logo {
    display: none;
    @media screen and (max-width: 991px) {
      display: initial;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 2px 15px;
  }

  .sectionDesktop {
    flex: auto;
    text-align: right;
    button {
      color: var(--color-white);
    }
  }

  .menu-item {
    padding: 0;
    margin: 0 2px;
    border-radius: var(--border-radius-sm);
    &:hover {
      background-color: var(--color-grey-10);
    }

    @media screen and (max-width: 800px) {
      display: none;
    }
  }

  @media (min-width: 600px) {
    .nav-button {
      background: transparent;
      border-radius: 500px;
      border: 1px solid transparent;
      font-size: 14px;
      font-weight: 500;
      height: 40px;
      letter-spacing: 1px;
      line-height: 2;
      margin: 0 10px;
      padding: 5px 20px;
      text-transform: none;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
